import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MySelect from "./MySelect";

const ContactSelectField = withStyles((theme) => {
  return {
    root: {
      marginTop: "1rem",
    },
  };
})((props) => {
  const { classes, ...rest } = props;

  return <MySelect {...rest} className={classes.root} />;
});
/*
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline
*/

export default ContactSelectField;
