import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import MyTextField from "../../fields/MyTextField";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import Loading from "../../Loading";
import { updateUserProfile } from "../../../api";
import { navigate } from "gatsby-link";
import editProfileScheme from "../../../helper/schemes/editProfileScheme";

import useStyles from "../../../styles/main";

const EditProfile = ({ userInfo }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    first_name: userInfo?.first_name,
    last_name: userInfo?.last_name,
    company: userInfo?.company,
    tax_number: userInfo?.tax_number,
    street: userInfo?.street,
    postal_code: userInfo?.postal_code,
    city: userInfo?.city,
    password: "",
  };

  const submitData = async (data, formikProps) => {
    setLoading(true);
    const unauthorizedCallback = () => {
      if (typeof window !== `undefined`) {
        navigate("/login");
      }
    };

    updateUserProfile(data, unauthorizedCallback).then(() => {
      setLoading(false);
      formikProps.resetForm();
    });
  };

  if (loading) return <Loading />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editProfileScheme}
      onSubmit={(values, formikProps) => {
        // same shape as initial values
        submitData(values, formikProps);
      }}
    >
      {(props) => (
        <Form>
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
              <Grid container spacing={2}>
                <Typography variant="h6">Persönliche Daten anpassen</Typography>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="first_name"
                    label="Vorname"
                    name="first_name"
                    autoComplete="firstname"
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="last_name"
                    label="Nachname"
                    id="last_name"
                    autoComplete="lastname"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    defaultValue={userInfo.email}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="company"
                    label="Firma"
                    name="company"
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    fullWidth
                    name="tax_number"
                    label="USt-IdNr."
                    type="text"
                    id="tax_number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Anschrift</Typography>
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="street"
                    label="Adresse"
                    name="street"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="postal_code"
                    label="PLZ"
                    name="postal_code"
                    autoComplete="plz"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="city"
                    label="Stadt"
                    name="city"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    defaultValue="Deutschland"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Land"
                    id="country"
                    name="country"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Bestätigung: </Typography>
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="password"
                    label="Passwort"
                    name="password"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12} className={classes.btn}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Speichern
                  </Button>
                </Grid>
                {/* <Grid item xs={12} className={classes.deleteBtn}>
                  <Button color="secondary">Account Löschen</Button>
                </Grid> */}
              </Grid>
            </div>
          </Container>
          {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
};

export default connect((state) => ({
  userInfo: state?.user?.info,
}))(EditProfile);
