import * as Yup from "yup";
import isValidIbanNumber from "../isValidIbanNumber";
require("./globalScheme");

const editAccountScheme = Yup.object().shape({
  account_holder: Yup.string().isValidAccountHolder(),
  password: Yup.string(),
  has_iban: Yup.array().of(Yup.mixed().oneOf(["yes"])),
  iban: Yup.string().when("has_iban", {
    is: (field) => field.includes("yes"),
    then: Yup.string()
      .isAlphaNumeric()
      .test(
        "ibanValidation",
        "Die eingegebene IBAN ist nicht korrekt.",
        (value) => isValidIbanNumber(value)
      ),
  }),
});
export default editAccountScheme;
