import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AuthenticatedLayout from "../layout/Layout";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Paper } from "@material-ui/core";
import EditProfile from "../forms/EditProfile";
import EditAccountDetails from "../forms/EditAccountDetails";
import EditPassword from "../forms/EditPassword";
import Message from "../Message";
import actions from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import UnsubscribeDialog from "../pages/Unsubscribe";

import useStyles from "../../../styles/main";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Settings = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const dispatch = useDispatch();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  useEffect(() => {
    return () => {
      dispatch(actions.message.removeMessage());
    };
  }, []);

  return (
    <AuthenticatedLayout>
      <div className={classes.root}>
        <Container maxWidth="md">
          <Typography
            variant="h2"
            gutterBottom
            align="left"
            className="text-[40px] font-[400] sm:leading-[72px] text-darkGreen md:text-[60px]"
          >
            Einstellungen
          </Typography>
          <Paper position="static" className={classes.paperSetting}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              variant="fullWidth"
              centered
              indicatorColor="secondary"
              className={classes.tabs}
            >
              <Tab label="Persönlich" />
              <Tab label="Bankverbindung" />
              <Tab label="Sicherheit" />
              <Tab label="Kündigung" />
            </Tabs>
          </Paper>
          <TabPanel value={currentTab} index={0}>
            <EditProfile />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <EditAccountDetails />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <EditPassword />
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <UnsubscribeDialog />
          </TabPanel>
        </Container>
        <Container maxWidth="sm">
          <Message />
        </Container>
      </div>
    </AuthenticatedLayout>
  );
};

export default Settings;
