import React from "react";
import AuthenticatedLayout from "../../../components/authenticated/layout/Layout";
import Quotes from "../../../components/Renewal/Quotes";

const SubPage = () => {
  return (
    <AuthenticatedLayout>
      <div>
        <main
          className={
            "min-h-[80vh] mx-auto mt-16 max-w-5xl px-4 sm:mt-16 py-[5rem]"
          }
        >
          <Quotes />
        </main>
      </div>
    </AuthenticatedLayout>
  );
};

export default SubPage;
