import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form } from "formik";
import { Box } from "@material-ui/core";
import MyCheckboxGroup from "../../fields/MyCheckboxGroup";
import store from "../../../redux/store";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";

import Container from "@material-ui/core/Container";

import pdfagb from "../../../media/pdf/AGB_M3E_GmbH.pdf";
import Loading from "../../Loading";

import { navigate } from "gatsby";

import ContactSelectField from "../../fields/ContactSelect";

import { BASE_URL } from "../../../api/config";
import actions from "../../../redux/actions";

require("../../../helper/schemes/globalScheme");

const FormDialog = (props) => {
  const [firstDialog, setFirstDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [reActivateDialog, setReActivateDialog] = useState(false);
  const [reactivationConfirmDialog, setReactivationConfirmDialog] =
    useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFirstDialog(props.open);
  }, [props.open]);

  const handleFirstDialog = () => {
    setFirstDialog(true);
  };
  const handleConfirmDialog = () => {
    setFirstDialog(false);
    setConfirmDialog(true);
  };

  const handleReActivateDialog = () => {
    setReActivateDialog(true);
  };
  const handleClose = () => {
    setFirstDialog(false);
    setReActivateDialog(false);
  };
  const handleReactivationConfirmDialog = () => {
    setReActivateDialog(false);
    setReactivationConfirmDialog(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialog(false);
    store.getState()?.user.subscription.deleted_at &&
      store.dispatch(actions.user.resetUser());
    navigate(`/login`);
  };
  const Unsubscribtion = async (
    { unsubscribed_reason },
    unauthorizedCallback
  ) => {
    setLoading(true);
    const token = store.getState()?.user?.token;
    const resp = await fetch(`${BASE_URL}/thg/unsubscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        unsubscribed_reason,
        site_ref: "stellantis",
      }),
    });
    const json = await resp.json();
    const unsubscribed_at = json.data.unsubscribed_at;
    const deleted_at = json.data.deleted_at;
    setLoading(false);
    store.dispatch(
      actions.user.updateSubscription({
        unsubscribed_at,
        deleted_at,
      })
    );
    if (resp.status === 403) {
    } else {
      handleConfirmDialog();
    }
  };

  const Subscription = async (unauthorizedCallback) => {
    setLoading(true);
    const token = store.getState()?.user?.token;
    const resp = await fetch(`${BASE_URL}/thg/reactive-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        site_ref: "stellantis",
      }),
    });
    const json = await resp.json();
    const unsubscribed_at = json.data.unsubscribed_at;
    const deleted_at = json.data.deleted_at;
    store.dispatch(
      actions.user.updateSubscription({
        unsubscribed_at,
        deleted_at,
      })
    );
    setLoading(false);
    if (resp.status === 409) {
      alert("error");
    } else {
      if (unsubscribed_at === null) handleReactivationConfirmDialog();
    }
  };

  const reactivationScheme = Yup.object().shape({
    agb: Yup.bool() // use bool instead of boolean
      .oneOf([true], "Bitte stimmen Sie den AGB zu."),
  });
  if (loading) return <Loading />;
  return (
    <div>
      <Container component="main" maxWidth="sm">
        {store.getState()?.user?.subscription?.unsubscribed_at ? (
          <div>
            <h2>Konto reaktivieren</h2>
            Sie haben die Möglichkeit, Ihr Konto wiederherzustellen. Bestätigen
            Sie hierzu unsere AGB und klicken Sie auf den Button Konto
            reaktivieren. Im Anschluss erhalten Sie von uns eine E-Mail mit der
            Auftragsbestätigung.
            <br></br>
            <Button
              variant="contained"
              sx={{
                width: 200,
                marginTop: 4,
                backgroundColor: "#13225c",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#1f4677",
                  color: "#fff",
                },
              }}
              onClick={handleReActivateDialog}
            >
              Konto reaktivieren
            </Button>
          </div>
        ) : (
          <div>
            <h2>
              Sie wollen unseren THG-Quotenservice nicht mehr nutzen? Wie
              schade, wir werden Sie vermissen.
            </h2>
            Klicken Sie hier, um Ihren bestehenden Vertrag mit der M3E GmbH zu
            kündigen.
            <br></br>
            <Button
              variant="contained"
              sx={{
                width: 170,
                marginTop: 4,
                backgroundColor: "#13225c",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#1f4677",
                  color: "#fff",
                },
              }}
              onClick={handleFirstDialog}
            >
              Jetzt kündigen
            </Button>
          </div>
        )}
        <Dialog open={firstDialog}>
          <DialogTitle
            sx={{
              fontSize: 18,
              m: "auto",
              width: "fit-content",
            }}
          >
            Hiermit kündigen Sie ihren Vertrag mit der M3E GmbH zur Vermarktung
            Ihrer THG-Quoten.
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Formik
                initialValues={{
                  unsubscribed_reason: "",
                }}
                onSubmit={async (values, formikProps) => {
                  Unsubscribtion(values, formikProps);
                }}
              >
                <Form method="POST">
                  <div>
                    <Box
                      noValidate
                      component="form"
                      sx={{
                        display: "flex",
                        m: "auto",
                        width: "fit-content",
                      }}
                    >
                      <DialogContentText>
                        Als Folge Ihrer Kündigung können Sie unseren
                        THG-Quotenservice nicht mehr nutzen:
                        <ul>
                          <li>Sie können keine neuen Fahrzeuge hochladen</li>{" "}
                          <li>
                            Sobald die Quote ausgezahlt bzw. abgelehnt wurde,
                            haben Sie keinen Zugriff mehr auf die Plattform{" "}
                          </li>
                          <li>
                            Ihre Daten werden nach Ablauf des für uns relevanten
                            Abrechnungszeitraums gelöscht
                          </li>
                        </ul>
                        Sie haben die Möglichkeit, Ihr Konto bis zur endgültigen
                        Löschung zu reaktivieren. Nutzen Sie dafür die
                        Wiederherstellungsfunktion. Dafür müssen Sie nur ein
                        weiteres Fahrzeug hinzufügen oder den
                        Wiederherstellungsbutton in der nachfolgend versendeten
                        E-Mail klicken.
                      </DialogContentText>
                    </Box>

                    <Box
                      noValidate
                      component="form"
                      sx={{
                        width: "fit-content",
                        display: "flex",
                      }}
                    >
                      <DialogContentText>
                        <b
                          sx={{
                            mt: "20",
                            width: "fit-content",
                            display: "flex",
                          }}
                        >
                          Bitte geben Sie einen Grund für Ihre Kündigung an:
                        </b>
                        <ContactSelectField
                          name="unsubscribed_reason"
                          variant="outlined"
                          id="unsubscribed_reason"
                          options={[
                            {
                              label: "Ich möchte hierzu keine Angaben machen.",
                              value: "Ich möchte hierzu keine Angaben machen.",
                            },
                            {
                              label:
                                "Ich habe einen anderen Anbieter gefunden, der eine höhere Auszahlung leistet.",
                              value:
                                "Ich habe einen anderen Anbieter gefunden, der eine höhere Auszahlung leistet.",
                            },
                            {
                              label:
                                "Ich bin unzufrieden mit dem Service der M3E",
                              value:
                                "Ich bin unzufrieden mit dem Service der M3E",
                            },
                            {
                              label:
                                "Ich möchte die THG-Quote doch nicht vermarkten lassen.",
                              value:
                                "Ich möchte die THG-Quote doch nicht vermarkten lassen.",
                            },
                          ]}
                          xs={12}
                          sm={6}
                          style={{
                            marginLeft: 5,
                            marginTop: 20,
                            width: 530,
                            display: "flex",
                          }}
                        />
                      </DialogContentText>
                    </Box>

                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        variant="outlined"
                        sx={{
                          width: 130,
                          backgroundColor: "#13225c",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#1f4677",
                            color: "#fff",
                          },
                        }}
                      >
                        Abbrechen
                      </Button>

                      <Button
                        variant="outlined"
                        sx={{
                          width: 200,
                          backgroundColor: "#13225c",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#1f4677",
                            color: "#fff",
                          },
                        }}
                        type="submit"
                      >
                        Bestätigen
                      </Button>
                    </DialogActions>
                  </div>
                </Form>
              </Formik>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          open={confirmDialog}
          handleClose={() => setConfirmDialog(false)}
        >
          <DialogTitle
            sx={{
              fontSize: 18,
              m: "auto",
              width: "fit-content",
            }}
          >
            Bestätigung
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ihre Kündigung ist soeben bei uns eingegangen. Sie erhalten in
              Kürze eine E-Mail mit der Bestätigung Ihrer Kündigung. Falls Sie
              Ihr Konto reaktivieren möchten, klicken Sie bitte auf den
              entsprechenden Button in der soeben versendet E-Mail.
            </DialogContentText>
            <DialogActions>
              <Box
                noValidate
                component="form"
                sx={{
                  display: "flex",
                  marginTop: 20,

                  marginX: "auto",
                  width: "fit-content",
                }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  onClick={closeConfirmDialog}
                  sx={{
                    width: 210,

                    borderColor: "#13225c",
                    color: "#13225c",
                    "&:hover": {
                      backgroundColor: "#13225c",
                      color: "#fff",
                    },
                  }}
                >
                  OK
                </Button>
              </Box>
            </DialogActions>
          </DialogContent>
        </Dialog>
        {/* Private */}
        <Dialog open={reActivateDialog}>
          <DialogTitle
            sx={{
              fontSize: 18,
              m: "auto",
              width: "fit-content",
            }}
          >
            Konto reaktivieren
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Formik
                initialValues={{
                  agb: false,
                }}
                validationSchema={reactivationScheme}
                onSubmit={async (values, formikProps) => {
                  Subscription(values, formikProps);
                }}
              >
                <Form method="POST">
                  <div>
                    <Box
                      noValidate
                      component="form"
                      sx={{
                        display: "flex",
                        m: "auto",
                        width: "fit-content",
                      }}
                    >
                      <DialogContentText>
                        Sie haben die Möglichkeit, Ihr Konto wiederherzustellen.
                        Bestätigen Sie hierzu unsere AGB und klicken Sie auf den
                        Button <i>Konto reaktivieren</i>. Im Anschluss erhalten
                        Sie von uns eine E-Mail mit der Auftragsbestätigung.
                      </DialogContentText>
                    </Box>

                    <Box
                      noValidate
                      component="form"
                      sx={{
                        width: "fit-content",
                        display: "flex",
                      }}
                    >
                      <DialogContentText>
                        <MyCheckboxGroup
                          options={[
                            {
                              label: (
                                <Box style={{ textAlign: "left" }}>
                                  <Typography>
                                    Hiermit bestätige ich, dass ich die {""}
                                    <a
                                      component="a"
                                      target="_blank"
                                      download
                                      href={pdfagb}
                                    >
                                      AGB
                                    </a>{" "}
                                    gelesen habe.
                                  </Typography>
                                </Box>
                              ),
                            },
                          ]}
                          name="agb"
                        />
                      </DialogContentText>
                    </Box>

                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        variant="outlined"
                        sx={{
                          width: 130,
                          backgroundColor: "#13225c",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#1f4677",
                            color: "#fff",
                          },
                        }}
                      >
                        Abbrechen
                      </Button>

                      <Button
                        variant="contained"
                        sx={{
                          width: 200,
                          backgroundColor: "#13225c",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#1f4677",
                            color: "#fff",
                          },
                        }}
                        type="submit"
                      >
                        Konto reaktivieren
                      </Button>
                    </DialogActions>
                  </div>
                </Form>
              </Formik>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          open={reactivationConfirmDialog}
          handleClose={() => setReactivationConfirmDialog(false)}
        >
          <DialogTitle
            sx={{
              fontSize: 18,
              m: "auto",
              width: "fit-content",
            }}
          >
            Die Wiederherstellung war erfolgreich.
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Sie erhalten von uns in Kürze eine Bestätigungsmail.
            </DialogContentText>
            <DialogActions>
              <Box
                noValidate
                component="form"
                sx={{
                  display: "flex",
                  marginTop: 20,

                  marginX: "auto",
                  width: "fit-content",
                }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  onClick={closeConfirmDialog}
                  sx={{
                    width: 210,

                    borderColor: "#13225c",

                    color: "#13225c",
                    "&:hover": {
                      backgroundColor: "#13225c",
                      color: "#fff",
                    },
                  }}
                >
                  OK
                </Button>
              </Box>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Box
          mt={8}
          sx={{
            width: 210,
            paddingTop: 300,
          }}
        ></Box>
      </Container>
    </div>
  );
};
export default FormDialog;
