import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    //color: theme.palette.primary.main,
    marginTop: "0.5rem",
  },
}));

const MySelect = ({ label, children, options, variant, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const classes = useStyles();

  return (
    <FormControl fullWidth error={!!errorText} variant={variant}>
      <InputLabel className={classes.root}>{label}</InputLabel>
      <Select {...field} {...props} label={label}>
        {options.map((option) => {
          if (typeof option === "string" || option instanceof String)
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          else if (
            typeof option === "object" &&
            !Array.isArray(option) &&
            option !== null
          ) {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          } else {
            return null;
          }
        })}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default MySelect;
