import React from "react";

const Description = () => {
  const year = new Date().getFullYear();
  return (
    <div className="my-2">
      <h1 className="text-[40px] font-[400] sm:leading-[72px] text-darkGreen md:text-[60px]">
        Liste Ihrer Fahrzeuge
      </h1>
      <p className="mt-[21px] text-[16px] font-[400] leading-[24px] text-[#070606]">
        Hier finden Sie eine Übersicht all Ihrer registrierten Fahrzeuge in
        Ihrem aktuellen Bearbeitungsstatus. Mit den Reitern {year - 1} und{" "}
        {year} können Sie zwischen den Bearbeitungszeiträumen wechseln.
      </p>
    </div>
  );
};

export default Description;
