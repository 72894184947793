import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form } from "formik";
import isValidIbanNumber from "../../../helper/isValidIbanNumber";
import { lighten } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import MyCheckboxGroup from "../../fields/MyCheckboxGroup";
import MyTextField from "../../fields/MyTextField";
import store from "../../../redux/store";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";

import { BASE_URL } from "../../../api/config";
import { Alert, AlertTitle } from "@material-ui/lab";
import actions from "../../../redux/actions";

import CyptoJS from "../../../helper/cryptojs";
require("../../../helper/schemes/globalScheme");

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    textAlign: "center",
    border: "none",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.primary.light,
    width: "100%",
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
}));
const FormDialog = () => {
  const [firstDialog, setFirstDialog] = useState(false);
  const [privateDialog, setPrivateDialog] = useState(false);
  const [companyDialog, setCompanyDialog] = useState(false);
  const [accountHolder, setAccountHolder] = useState(false);
  const [isApplicationApproved, setApplicationApproved] = useState(false);
  const [oldIBAN, setOldIBAN] = useState(false);

  useEffect(() => {
    const getApplicationApproved = async () => {
      const token = store.getState()?.user?.token;
      const response = await fetch(`${BASE_URL}/thg/is_quote_approved`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();
      setApplicationApproved(json.data);
    };
    getApplicationApproved();
    if (isApplicationApproved > 0) {
      const is_company = store.getState()?.user?.isCompany.is_company;
      if (is_company != 0 && is_company != 1) setFirstDialog(true);
      setAccountHolder(store.getState()?.user?.account.account_holder);
      if (
        store.getState()?.user?.account.iban !== null ||
        store.getState()?.user?.iban !== undefined
      ) {
        setOldIBAN(CyptoJS(store.getState()?.user?.account.iban));
      } else setOldIBAN(null);
    }
  }, [isApplicationApproved]);

  const [privateNotification, setPrivateNotification] = useState(false);
  const [companyNotification, setCompanyNotification] = useState(false);

  const handlePrivateDialog = () => {
    setFirstDialog(false);
    setPrivateDialog(true);
    setCompanyDialog(false);
  };
  const handleCompanyDialog = () => {
    setFirstDialog(false);
    setPrivateDialog(false);
    setCompanyDialog(true);
  };

  const handleClose = () => {
    setFirstDialog(false);
    setPrivateDialog(false);
    setCompanyDialog(false);
  };
  const handleBack = () => {
    setFirstDialog(true);
    setPrivateDialog(false);
    setCompanyDialog(false);
  };

  const updateIsCompany = async () => {
    const token = store.getState()?.user?.token;
    const response = await fetch(`${BASE_URL}/thg/is-company`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "*",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await response.json();
    const is_company = json.data.is_company;
    const newIban = json.data.iban;
    const newAccountHolder = json.data.account_holder;
    store.dispatch(
      actions.user.updateCompanyInfo({
        is_company,
      })
    );
    store.dispatch(
      actions.user.updateAccountInfo({
        account_holder: newAccountHolder,
        iban: newIban,
      })
    );
  };

  const updatePrivateinfo = async (
    { account_holder, iban, password, bank_details_confirmed_at = false },
    unauthorizedCallback
  ) => {
    const token = store.getState()?.user?.token;

    const resp = await fetch(`${BASE_URL}/thg/update-company`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "*",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        account_holder,
        iban,
        password,
        bank_details_confirmed_at,
        is_company: "private",
      }),
    });

    const json = await resp.json();

    if (json.errors) {
      setPrivateNotification("error");
    } else {
      setPrivateNotification("success");
      updateIsCompany();
    }
  };

  const updateCompanyInfo = async (
    {
      company,
      tax_number,
      password,
      has_tax_number,
      account_holder,
      iban,
      bank_details_confirmed_at,
    },
    unauthorizedCallback
  ) => {
    const token = store.getState()?.user?.token;
    const resp = await fetch(`${BASE_URL}/thg/update-company`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        company,
        tax_number,
        password,
        has_tax_number,
        is_company: "public",
        account_holder,
        iban,
        bank_details_confirmed_at,
      }),
    });

    const json = await resp.json();

    if (json.errors) {
      setCompanyNotification("error");
    } else {
      setCompanyNotification("success");
      updateIsCompany();
    }
  };

  const validationSchemePrivate = Yup.object().shape({
    iban: Yup.string()
      .isAlphaNumeric()
      .test(
        "ibanValidation",
        "Die eingegebene IBAN ist nicht korrekt.",
        (value) => isValidIbanNumber(value)
      ),
    account_holder: Yup.string().isValidAccountHolder(),
    bank_details_confirmed_at: Yup.array()
      .min(1, "Bitte bestätigen Sie die Angaben.")
      .required("Bitte bestätigen Sie die Angaben.")
      .of(Yup.mixed().oneOf(["yes"])),
    password: Yup.string().required(),
  });

  const validationSchemeCompany = Yup.object().shape({
    company: Yup.string().required("Firma ist notwendig"),
    has_tax_number: Yup.array().of(Yup.mixed().oneOf(["yes"])),
    tax_number: Yup.string().when("has_tax_number", {
      is: (field) => field && field.includes("yes"),
      then: Yup.string().required("USt-IdNr. ist notwendig"),
    }),
    iban: Yup.string()
      .isAlphaNumeric()
      .test(
        "ibanValidation",
        "Die eingegebene IBAN ist nicht korrekt.",
        (value) => isValidIbanNumber(value)
      ),
    account_holder: Yup.string().isValidAccountHolder(),
    bank_details_confirmed_at: Yup.array()
      .min(1, "Bitte bestätigen Sie die Angaben.")
      .required("Bitte bestätigen Sie die Angaben.")
      .of(Yup.mixed().oneOf(["yes"])),
    password: Yup.string().required(),
  });

  return (
    <div>
      {/*
        <Button variant="outlined" onClick={handleFirstDialog}>
          Open form dialog
        </Button>
  */}
      <Dialog open={firstDialog}>
        <DialogTitle
          sx={{
            fontSize: 18,
            m: "auto",
            width: "fit-content",
          }}
        >
          Registrieren Sie sich als Privatkunde oder als Unternehmen?*
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            *Bitte beachten Sie hier, ob als Halter*in Ihrer registrierten
            Fahrzeuge eine Person oder ein Unternehmen eingetragen ist.
          </DialogContentText>
          <DialogActions>
            <Box
              noValidate
              component="form"
              sx={{
                display: "flex",
                marginTop: 20,

                marginX: "auto",
                width: "fit-content",
              }}
            >
              <Button
                type="button"
                variant="outlined"
                onClick={handlePrivateDialog}
                sx={{
                  width: 210,

                  borderColor: "#13225c",
                  color: "#13225c",
                  "&:hover": {
                    backgroundColor: "#13225c",
                    color: "#fff",
                    borderColor: "#13225c",
                  },
                }}
              >
                Privatkunde
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleCompanyDialog}
                sx={{
                  marginLeft: 2,
                  width: 210,
                  backgroundColor: "#13225c",
                  color: "#ffff",
                  "&:hover": {
                    backgroundColor: "#ffff",
                    color: "#13225c",
                    borderColor: "#13225c",
                  },
                }}
              >
                Unternehmen
              </Button>
            </Box>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* Private */}

      <Dialog open={privateDialog}>
        <DialogTitle>Privatkunde</DialogTitle>
        <DialogContent>
          {privateNotification && privateNotification === "success" && (
            <Box my={3} align="start" style={{ width: "100%" }}>
              <Alert severity="success">
                <AlertTitle variant="h6">Vielen Dank!</AlertTitle>
                <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
                  Wir haben Ihre Angaben erfolgreich gespeichert. Falls Sie
                  diese nachträglich ändern möchten, können Sie dies gern in
                  Ihren{" "}
                  <a href="/authenticated/personal">
                    {" "}
                    persönlichen Einstellungen
                  </a>{" "}
                  tun.
                </Typography>
              </Alert>
            </Box>
          )}
          {privateNotification && privateNotification === "error" && (
            <Box my={3} align="start" style={{ width: "100%" }}>
              <Alert severity="error">
                <AlertTitle variant="h6">Fehler!</AlertTitle>
                <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
                  Das Passwort ist inkorrekt.
                </Typography>
              </Alert>
            </Box>
          )}
          <DialogContentText>
            Bitte geben Sie nun Ihre Bankverbindung an. Diese benötigen wir für
            die Auszahlung Ihrer Erlöse.
          </DialogContentText>
          <DialogContentText>
            <Formik
              initialValues={{
                iban: oldIBAN,
                account_holder: accountHolder,
                password: "",
                bank_details_confirmed_at: [],
              }}
              validationSchema={validationSchemePrivate}
              onSubmit={async (values, formikProps) => {
                const strippedIban = values.iban.replace(/ /g, "");
                updatePrivateinfo(
                  { ...values, iban: strippedIban },
                  formikProps
                );
              }}
            >
              <Form method="POST">
                <div>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: "flex",
                      m: "auto",
                      width: "fit-content",
                    }}
                  >
                    <MyTextField
                      label="Kontoinhaber*in"
                      id="account_holder"
                      name="account_holder"
                      autoComplete="iban"
                      variant="standard"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 10,
                        width: 262,
                      }}
                    />
                    <MyTextField
                      label="IBAN"
                      id="iban"
                      name="iban"
                      autoComplete="iban"
                      variant="standard"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 10,
                        marginTop: 10,
                        width: 262,
                      }}
                    />
                  </Box>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: "flex",
                      marginTop: 5,
                      width: "fit-content",
                    }}
                  >
                    <MyCheckboxGroup
                      label="Alle Angaben korrekt?"
                      options={[
                        {
                          label: (
                            <Box style={{ textAlign: "left" }}>
                              <Typography variant="overline">Ja</Typography>
                            </Box>
                          ),
                          value: "yes",
                        },
                      ]}
                      name="bank_details_confirmed_at"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 10,
                        marginTop: 7,
                        width: 270,
                      }}
                    />
                    <MyTextField
                      label="Mit Passwort bestätigen"
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="password"
                      variant="standard"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 7,
                        width: 265,
                      }}
                    />
                  </Box>

                  <DialogActions>
                    {privateNotification &&
                    privateNotification === "success" ? (
                      <Button
                        onClick={handleClose}
                        variant="outlined"
                        sx={{
                          width: 130,
                          backgroundColor: "#13225c",
                          color: "#ffff",
                          "&:hover": {
                            backgroundColor: "#ffff",
                            color: "#13225c",
                            borderColor: "#13225c",
                          },
                        }}
                      >
                        schließen
                      </Button>
                    ) : (
                      <Button
                        onClick={handleBack}
                        variant="outlined"
                        sx={{
                          width: 100,
                          borderColor: "#13225c",
                          color: "#13225c",
                          "&:hover": {
                            backgroundColor: "#13225c",
                            color: "#fff",
                            borderColor: "#13225c",
                          },
                        }}
                      >
                        zurück
                      </Button>
                    )}
                    {privateNotification &&
                    privateNotification === "success" ? null : (
                      <Button
                        variant="contained"
                        sx={{
                          width: 100,
                          backgroundColor: "#13225c",
                          color: "#ffff",
                          "&:hover": {
                            backgroundColor: "#ffff",
                            color: "#13225c",
                            borderColor: "#13225c",
                          },
                        }}
                        type="submit"
                      >
                        speichern
                      </Button>
                    )}
                  </DialogActions>
                </div>
              </Form>
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={companyDialog} handleClose={() => setCompanyDialog(false)}>
        <DialogTitle>Unternehmen</DialogTitle>
        <DialogContent>
          {companyNotification && companyNotification === "success" && (
            <Box my={3} align="start" style={{ width: "100%" }}>
              <Alert severity="success">
                <AlertTitle variant="h6">Vielen Dank!</AlertTitle>
                <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
                  Wir haben Ihre Angaben erfolgreich gespeichert. Falls Sie
                  diese nachträglich ändern möchten, können Sie dies gern in
                  Ihren{" "}
                  <a href="/authenticated/personal">
                    {" "}
                    persönlichen Einstellungen
                  </a>{" "}
                  tun.
                </Typography>
              </Alert>
            </Box>
          )}
          {companyNotification && companyNotification === "error" && (
            <Box my={3} align="start" style={{ width: "100%" }}>
              <Alert severity="error">
                <AlertTitle variant="h6">Fehler!</AlertTitle>
                <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
                  Das Passwort ist inkorrekt.
                </Typography>
              </Alert>
            </Box>
          )}
          <DialogContentText>
            Bitte geben Sie nun ein paar Informationen zu Ihrem Unternehmen an.
          </DialogContentText>

          <DialogContentText>
            <Formik
              initialValues={{
                company: "",
                tax_number: "",
                has_tax_number: "",
                iban: oldIBAN,
                account_holder: accountHolder,
                password: "",
              }}
              validationSchema={validationSchemeCompany}
              onSubmit={(values, formikProps) => {
                const strippedIban = values.iban.replace(/ /g, "");
                updateCompanyInfo(
                  { ...values, iban: strippedIban },
                  formikProps
                );
              }}
            >
              {(props) => {
                return (
                  <Form method="PUT">
                    <div>
                      <Box
                        noValidate
                        component="form"
                        sx={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <MyTextField
                          margin="dense"
                          label="Firma"
                          variant="outlined"
                          id="company"
                          name="company"
                          style={{
                            marginLeft: 5,
                            marginTop: 20,
                            width: 265,
                          }}
                        />

                        <MyCheckboxGroup
                          label="Sind Sie Vorsteuerabzugesberechtigt?"
                          options={[
                            {
                              label: (
                                <Box style={{ textAlign: "left" }}>
                                  <Typography variant="overline">Ja</Typography>
                                </Box>
                              ),
                              value: "yes",
                            },
                          ]}
                          style={{
                            marginTop: 10,
                            marginLeft: 12,
                          }}
                          name="has_tax_number"
                        />
                      </Box>

                      {props.values.has_tax_number.includes("yes") && (
                        <MyTextField
                          variant="outlined"
                          fullWidth
                          name="tax_number"
                          label="USt-IdNr."
                          type="text"
                          id="tax_number"
                          style={{
                            marginLeft: 5,
                            marginTop: 10,
                            width: 265,
                          }}
                        />
                      )}
                      <Box
                        noValidate
                        component="form"
                        sx={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <MyTextField
                          label="Kontoinhaber*in"
                          id="account_holder"
                          name="account_holder"
                          autoComplete="iban"
                          variant="standard"
                          style={{
                            marginLeft: 5,
                            marginTop: 10,
                            width: 265,
                          }}
                        />
                        <MyTextField
                          label="IBAN"
                          id="iban"
                          name="iban"
                          autoComplete="iban"
                          variant="standard"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: 10,
                            marginTop: 10,
                            width: 260,
                          }}
                        />
                      </Box>
                      <Box
                        noValidate
                        component="form"
                        sx={{
                          display: "flex",
                          m: "auto",
                          width: "fit-content",
                        }}
                      >
                        <MyCheckboxGroup
                          label="Alle Angaben korrekt?"
                          options={[
                            {
                              label: (
                                <Box style={{ textAlign: "left" }}>
                                  <Typography variant="overline">Ja</Typography>
                                </Box>
                              ),
                              value: "yes",
                            },
                          ]}
                          name="bank_details_confirmed_at"
                          style={{
                            marginLeft: 8,
                            marginTop: 20,
                            width: 255,
                          }}
                        />
                        <MyTextField
                          label="Mit Passwort bestätigen"
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="password"
                          variant="standard"
                          style={{
                            marginLeft: 16,
                            marginTop: 10,
                            width: 260,
                          }}
                        />
                      </Box>
                      <DialogActions>
                        {companyNotification &&
                        companyNotification === "success" ? (
                          <Button
                            onClick={handleClose}
                            variant="outlined"
                            sx={{
                              width: 130,
                              backgroundColor: "#13225c",
                              color: "#ffff",
                              "&:hover": {
                                backgroundColor: "#ffff",
                                color: "#13225c",
                                borderColor: "#13225c",
                              },
                            }}
                          >
                            schließen
                          </Button>
                        ) : (
                          <Button
                            onClick={handleBack}
                            variant="outlined"
                            sx={{
                              width: 100,
                              borderColor: "#13225c",
                              color: "#13225c",
                              "&:hover": {
                                backgroundColor: "#13225c",
                                color: "#fff",
                                borderColor: "#13225c",
                              },
                            }}
                          >
                            zurück
                          </Button>
                        )}
                        {companyNotification &&
                        companyNotification === "success" ? null : (
                          <Button
                            variant="contained"
                            sx={{
                              width: 100,
                              backgroundColor: "#13225c",
                              color: "#ffff",
                              "&:hover": {
                                backgroundColor: "#ffff",
                                color: "#13225c",
                                borderColor: "#13225c",
                              },
                            }}
                            type="submit"
                          >
                            speichern
                          </Button>
                        )}
                      </DialogActions>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default FormDialog;
