import React from "react";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../jss/footerStyles";
import Link from "../../Link";

const useStyles = makeStyles(styles);

const Footer = (props) => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div align="end">
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link href="/agb" className={classes.block}>
                AGB
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link href="/impressum" className={classes.block}>
                Impressum
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link href="/contact" className={classes.block}>
                Kontakt
              </Link>
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
